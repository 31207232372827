import { SettingsPageContainer, ContentContainer, UserSection, SectionTitle, UserInfo, ChangePass, PassTitle, LogoutBtn } from './styles'
import { User } from '../../models/user'
import HeaderPage from '../../components/HeaderPage'
import { useForm } from '../../hooks/form-hook'
import FormInput from '../../components/FormElements/FormInput'
import { MaxLengthValidator, MinLengthValidator, RequireValidator } from '../../utils/validators'
import Button from '../../components/FormElements/Button'
import { changePass, logout } from '../../services'
import { db } from '../../db/db'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useState } from 'react'
import Loading from '../../components/Loading'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

interface Props {
  user?: User []
}

const SettingsPage = ({ user }: Props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false)

  const [formState, inputHandler] = useForm(
    {
      newPass: {
        value: '',
        isValid: false,
      },
      newPassConfirm: {
        value: '',
        isValid: false,
      },
    },
    false
  );

  const submitPass = async () => {
    if (!formState.isValid) {
      toast.error('Preencha o formulário com dados validos!')
      setLoading(false)
      return
    }
    if (formState.inputs.newPass.value !== formState.inputs.newPassConfirm.value) {
      toast.error('As senhas devem ser iguais!')
      setLoading(false)
      return
    }
    setLoading(true)

    if (user) {
      try {
        await changePass(user[0].access_token, formState.inputs.newPass.value)
        toast.success('Senha alterada com sucesso!')
        setShowPasswordForm(false)
        setLoading(false)
      } 
      catch (error: any) {
        console.log(error)
        setLoading(false)
        toast.error('Tivemos um problema, tente novamente mais tarde!')
        if (error.response.status === 403) {
          db.user.clear()
          db.taboola.clear()
          navigate('/login')
        }
      }
    }
  }

  const logoutUser = () => {
    if(user) {
      logout(user[0].access_token)
      .then(resp => {
        db.taboola.clear()
        db.user.clear()
        navigate('/login')
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
  
  return (
    <SettingsPageContainer >
      <HeaderPage title='Configurações'/>
      
      <ContentContainer>
        <UserSection>
          <SectionTitle>Informações Pessoais</SectionTitle>
           
          <UserInfo>Nome: <span>{user && user[0].name}</span></UserInfo>
          <UserInfo>Email: <span>{user && user[0].email}</span></UserInfo>

          <ChangePass>
            <PassTitle>Senha</PassTitle>
            
            {!showPasswordForm? (
              <Button type='submit' size='medium' model='confirm' width='200px' text='Alterar Senha' mobileWidth='80%' onClick={() => {setShowPasswordForm(true)}}/>
            ) : (
              loading ? (
                <Loading />
              ) : (
                <div style={{maxWidth: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <FormInput 
                    id='newPass' 
                    type='password'
                    label='Nova senha'
                    invalidMessage='Por favor, preencha a nova senha'
                    validators={[RequireValidator(), MinLengthValidator(5), MaxLengthValidator(20)]}
                    onInput={inputHandler}
                    placeholder='Nova senha'
                  />
                  <FormInput 
                    id='newPassConfirm' 
                    type='password'
                    label='Confirmar nova senha'
                    invalidMessage='Por favor, preencha confirmação de senha'
                    validators={[RequireValidator(), MinLengthValidator(5), MaxLengthValidator(20)]}
                    onInput={inputHandler}
                    placeholder='Confirmar nova senha'
                  />

                  <div style={{display: 'flex', gap: '15px'}}>
                    <Button type='submit' size='medium' model='cancel' width='50%' text='Cancelar' mobileWidth='80%' onClick={() => setShowPasswordForm(false)}/>
                    <Button type='submit' size='medium' model='confirm' width='50%' text='Enviar' mobileWidth='80%' onClick={() => submitPass()}/>
                  </div>  
                </div>
              )
            )}
          </ChangePass>
        </UserSection>
      </ContentContainer>
      <ToastContainer />

      <LogoutBtn onClick={() => logoutUser()}>
        <ExitToAppIcon style={{ fill: '#fff'}}/>
      </LogoutBtn>
    </SettingsPageContainer>
  )
}

export default SettingsPage

