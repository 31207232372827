import { useEffect, useState } from 'react'
import { User } from '../../models/user'
import "react-toastify/dist/ReactToastify.css";
import { loadAllUsers } from '../../services';
import HeaderPage from '../../components/HeaderPage';
import { ClientContainer, ClientsPageContainer, ContentContainer, IconContainer, InfoContainer, ClientName, ClientEmail } from './styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ModalNewAsaasClient from '../../components/Modals/CreateAsaasUser';
import { ToastContainer } from 'react-toastify';

interface Props {
  user?: User []
}

const ClientsPage = ({ user }: Props) => {
  const [allClients, setAllClients] = useState<User[]>()
  const [selectedClient, setSelectedClient] = useState<User>()

  useEffect(() => {
    const controller = new AbortController();
    
    if(user && user.length>0) {
      loadAllUsers(user[0].access_token, controller)
      .then(resp => {
        console.log(resp)
        setAllClients(resp.data)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }, [user])

  const openModal = (client: User) => {
    setSelectedClient(client)
    const modal = document.getElementById('new-client')
    if (modal) modal.style.display = 'flex'
  }

  return (
    <ClientsPageContainer>
      <HeaderPage title='Clientes'/>
      <ContentContainer>
        {allClients && (
          allClients.map(client => (
            <ClientContainer>
              <IconContainer onClick={() => openModal(client)}>
                <ModeEditIcon style={{ fill: 'rgb(119 190 77)', width: '20px', height: '20px' }}/>
              </IconContainer>

              <InfoContainer>
                <ClientName>{client.name}</ClientName>
                <ClientEmail>{client.email}</ClientEmail>
              </InfoContainer>
            </ClientContainer>
          ))
        )}
      </ContentContainer>

      <div id='new-client' className='modal'>
          {user && <ModalNewAsaasClient user={user} client={selectedClient}/>}
      </div>
      
      <ToastContainer />
    </ClientsPageContainer>
  )
}

export default ClientsPage
