import { CampaingAdsPageContainer, TabsContainer, ContentContainer, Tab, AdsContainer} from './styles'
import { useEffect, useState } from 'react'
import { User } from '../../../models/user'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { loadAdsOptimizationData } from '../../../services'
import Loading from '../../../components/Loading'
import { AdsSummaryModel } from '../../../models/ads-summary'
import AdsSummaryLine from '../../../components/CampaingElements/AdsSummaryRow'
import { db } from '../../../db/db'
import { getTaboolaAdsData } from '../../../services/taboola'
import { AccountTaboolaDataModel } from '../../../models/account-taboola-data'

interface Props {
  user?: User []
  taboolaInfo?: AccountTaboolaDataModel[]
}

const CampaingAdsPage = ({ user, taboolaInfo }: Props) => {
  const navigate = useNavigate()

  const [loadingAds, setLoadingAds] = useState<boolean>(true)
  const [ads, setAds] = useState<AdsSummaryModel[]>()
  const { pickDate, dataLocation, loading, optimizationData, editAdStatusMessage } = useOutletContext<any>();

  useEffect(() => {
    const controller = new AbortController();

    if (user && user.length>0) {
      loadAdsOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, pickDate, controller)
      .then(resp => {
        const adsOptInfo = resp.data
        
        if(taboolaInfo) {
          const acc_id = optimizationData.sub_account? optimizationData.sub_account : taboolaInfo[0].account_id
          getTaboolaAdsData(acc_id, dataLocation.campaignSummary.external_id, taboolaInfo[0].access_token, controller)
          .then(response => {
            const adsFinal = adsOptInfo.map(ad => {
              const thisAd = response.data.results.find((element: any) => element.id === ad.id_ads_taboola);
              ad.status = thisAd.is_active
              return ad
            })
            setAds(adsFinal)
            setLoadingAds(false)
          })
          .catch(err => {
            console.log(err)
          })
        }
      })
      .catch(err => {
        console.log(err)
        if (err.response.status === 403) {
          db.taboola.clear()
          db.user.clear()
          navigate('/login')
        }
      }) 
    }
  }, [user, pickDate, dataLocation, navigate, optimizationData, taboolaInfo])

  const redirectPage = (tab: string) => {
    navigate('/campaigns/taboola/' + dataLocation.campaignSummary.id + `/${tab}`, {state: { campaignSummary: dataLocation.campaignSummary }})
  }

  return (
    <CampaingAdsPageContainer>
        <ContentContainer>
          <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px', width: '100%'}}>
            <TabsContainer>
                <Tab onClick={() => redirectPage('funnels')}>Funis</Tab>
                <Tab className='active'>Ads</Tab>
                <Tab onClick={() => redirectPage('sites')}>Sites</Tab>
            </TabsContainer>

          </div>

          <AdsContainer>
          {loadingAds || loading? (
            <Loading />
          ) : (
            ads && ads.length > 0? (
              ads.map((item, index) => (
              <AdsSummaryLine key={index} ad={item} campaign={dataLocation.campaignSummary} taboolaInfo={taboolaInfo} pickDate={pickDate} editAdStatusMessage={editAdStatusMessage}/>
            ))) : (
              'Nenhum click registrado no periodo selecionado.'
            )
          )}
          </AdsContainer>
        </ContentContainer>
    </CampaingAdsPageContainer>
  )
}


export default CampaingAdsPage