import { CampaignStepsSummary } from '../../../models/campaign-steps-summary'
import PizzaGraph from '../../UiElements/PizzaGraph'
import { FunnelLine, FunnelTitle, FunnelLink, DataContainer, StepsContainer, InfoContainer, DataValues, Step, Dot, Percentage, Line, Arrow } from './styles'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
    clicks_taboola: number
    stepsSummary: CampaignStepsSummary
    expenses: number
}

const FunnelSummaryLine = ({ stepsSummary, expenses, clicks_taboola }: Props) => {
    const dataClicksGraph = [
        { value: stepsSummary.total_clicks, color: 'rgb(119 190 77)', label: 'Page views' },
        { value: clicks_taboola - stepsSummary.total_clicks, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];

    const dataStep1 = [
        { value: stepsSummary.step_1_views, color: 'rgb(119 190 77)', label: 'Page views' },
        { value: stepsSummary.total_step_1 - stepsSummary.step_1_views, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];

    const dataStep2 = [
        { value: stepsSummary.step_2_views, color: 'rgb(119 190 77)', label: 'Start passo 3' },
        { value: stepsSummary.total_step_2 - stepsSummary.step_2_views, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];

    const dataStep3 = [
        { value: stepsSummary.step_3_views, color: 'rgb(119 190 77)', label: 'Page views' },
        { value: stepsSummary.total_step_3 - stepsSummary.step_3_views, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];

    const dataCheckout = [
        { value: stepsSummary.checkout_views, color: 'rgb(119 190 77)', label: 'Page views' },
        { value: stepsSummary.total_checkout - stepsSummary.checkout_views, color: 'rgb(185 185 185)', label: 'Abandonos' },
        //{ value: stepsSummary.total_step_3? stepsSummary.total_step_3 - stepsSummary.total_checkout : stepsSummary.total_step_2? stepsSummary.total_step_2 - stepsSummary.total_checkout : stepsSummary.total_step_1? stepsSummary.total_step_1 - stepsSummary.total_checkout : clicks_taboola - stepsSummary.total_checkout, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];

    const dataSales = [
        { value: stepsSummary.total_sales, color: 'rgb(119 190 77)', label: 'Conversões' },
        { value: stepsSummary.checkout_views - stepsSummary.total_sales, color: 'rgb(185 185 185)', label: 'Abandonos' },
    ];
    const size = {
        width: 80,
        height: 80
    }

    return(
        <FunnelLine>
            <DataContainer>
                <div>
                    <FunnelTitle>{stepsSummary.name}</FunnelTitle>
                    <FunnelLink>{stepsSummary.link}</FunnelLink>
                </div>

                <InfoContainer>
                    <DataValues>Gasto: <strong>$ {expenses?.toFixed(2).replace('.', ',')}</strong></DataValues>
                    <DataValues>Fatur.: <strong>$ {stepsSummary.revenue?.toFixed(2).replace('.', ',')}</strong></DataValues>
                    <DataValues>ROAS: <strong>{expenses>0? ((stepsSummary.revenue/expenses)*100).toFixed(2).replace('.', ',') : 0.00}%</strong></DataValues>
                </InfoContainer>
            </DataContainer>
           
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', borderTop: '1px solid #dfdfdf'}}>
                <div style={{width: 'fit-content', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <StepsContainer>
                        <Step>
                            <PizzaGraph labelChart={'Passo 1'} data={dataClicksGraph} size={size} labelDonut={clicks_taboola}/>
                            <Arrow>
                                <ExpandLessIcon style={{ 
                                    fill: 'rgb(119 190 77)',
                                    width: '30px', 
                                    height: '30px', 
                                    rotate: '90deg'
                                }} />
                            </Arrow>
                        </Step>
                        {stepsSummary.total_step_1 > 0 && (
                            <Step>
                                <PizzaGraph labelChart={'Passo 2'} data={dataStep1} size={size} labelDonut={stepsSummary.total_step_1}/>
                                <Percentage>{clicks_taboola>0?(stepsSummary.total_step_1/clicks_taboola*100).toFixed(2) : 0}%</Percentage>
                                <Arrow>
                                    <ExpandLessIcon style={{ 
                                        fill: 'rgb(119 190 77)',
                                        width: '30px', 
                                        height: '30px', 
                                        rotate: '90deg',
                                    }} />
                                </Arrow>
                            </Step>
                        )}
                        {stepsSummary.total_step_2 > 0 && (
                            <Step>
                                <PizzaGraph labelChart={'Passo 3'} data={dataStep2} size={size} labelDonut={stepsSummary.total_step_2}/>
                                <Percentage>{stepsSummary.total_step_1 > 0?(stepsSummary.total_step_2/stepsSummary.total_step_1*100).toFixed(2) : 0}%</Percentage>
                                <Arrow>
                                    <ExpandLessIcon style={{ 
                                        fill: 'rgb(119 190 77)',
                                        width: '30px', 
                                        height: '30px', 
                                        rotate: '90deg',
                                    }} />
                                </Arrow>
                            </Step>
                        )}
                        {stepsSummary.total_step_3 > 0 && (
                            <Step>
                                <PizzaGraph labelChart={'Passo 4'} data={dataStep3} size={size} labelDonut={stepsSummary.total_step_3}/>
                                <Percentage>{stepsSummary.total_step_2 > 0? (stepsSummary.total_step_3/stepsSummary.total_step_2*100).toFixed(2): 0}%</Percentage>
                                <Arrow>
                                    <ExpandLessIcon style={{ 
                                        fill: 'rgb(119 190 77)',
                                        width: '30px', 
                                        height: '30px', 
                                        rotate: '90deg',
                                    }} />
                                </Arrow>
                            </Step>
                        )}
                        <Step>
                            <PizzaGraph labelChart={'Checkout'} data={dataCheckout} size={size} labelDonut={stepsSummary.total_checkout}/>
                            {stepsSummary.total_step_3 > 0? (
                                <Percentage>{(stepsSummary.total_checkout/stepsSummary.total_step_3*100).toFixed(2)}%</Percentage>
                            ) : (
                                stepsSummary.total_step_2 > 0? (
                                    <Percentage>{(stepsSummary.total_checkout/stepsSummary.total_step_2*100).toFixed(2)}%</Percentage>
                                ) : (
                                    stepsSummary.total_step_1 > 0? (
                                        <Percentage>{(stepsSummary.total_checkout/stepsSummary.total_step_1*100).toFixed(2)}%</Percentage>
                                    ) : (
                                        <Percentage>{clicks_taboola > 0 ?(stepsSummary.total_checkout/clicks_taboola*100).toFixed(2) : 0}%</Percentage>
                                    )
                                )
                            )}
                            <Arrow>
                                <ExpandLessIcon style={{ 
                                    fill: 'rgb(119 190 77)',
                                    width: '30px', 
                                    height: '30px', 
                                    rotate: '90deg',
                                }} />
                            </Arrow>
                        </Step>
                        <Step>
                            <PizzaGraph labelChart={'Conversões'} data={dataSales} size={size} labelDonut={stepsSummary.total_sales}/>
                            <Percentage>{stepsSummary.total_checkout > 0? (stepsSummary.total_sales/stepsSummary.total_checkout*100).toFixed(2): 0}%</Percentage>
                            <Dot />
                        </Step>
                    </StepsContainer>
                    <Line />
                </div>
            </div>
            
        </FunnelLine>
    )
}

export default FunnelSummaryLine