import axios, { AxiosRequestConfig } from "axios";

export const config: AxiosRequestConfig = {
	baseURL: 'https://graph.facebook.com/v12.0/',
	responseType: 'json',
	headers: {
		accept: 'application/json',
		'content-type': 'application/json',
//		authorization: 'Bearer CS04AAAAAAAAEXpoQwEAAAAAGAEgACkpXIn4jwEAADooYWZiMWQ5YWMyYjU1NmFjZTFlNTcwMjU0ZmVhOGI2ZjUzOGRlN2M3NEAC::c313ca::391e1f'
	},
	timeout: 40000,
	withCredentials: false,
	maxRedirects: 5,
	validateStatus: (status: number) => status >= 200 && status < 300,
	transformResponse: (response) => {
		if (response !== null && response.error) {
			console.log(response.error);
		}
		let resp;
		try {
			resp = JSON.parse(response);
		} catch {
			resp = response;
		}
		return resp;
	},
};

export const getMetaCampaignData = async (campaign_id: string, access_token: string, controller: AbortController) => {
	config.url = `/${campaign_id}?fields=daily_budget,budget_remaining,status,name,objective,bid_strategy,effective_status&access_token=${access_token}`
	config.method = 'get'
	config.signal = controller.signal

	return axios.request<any>(config);
};
