import { CardContainer, Header, Title, CampaingId, SummaryContainer, 
  SummaryInfo, SummaryData, GraphContainer, FunnelStepsContainer, 
  GraphStepContainer, TabsContainer, Tab } from './styles'
import { CampaignSummaryModel } from '../../../models/campaing-summary'
import { useNavigate } from 'react-router-dom';
import PizzaGraph from '../../UiElements/PizzaGraph';
import { useState } from 'react';
import $ from 'jquery'
import DeleteIcon from '@mui/icons-material/Delete';
import { User } from '../../../models/user';
import MetaLogo from '../../../assets/meta.png'
import TaboolaLogo from '../../../assets/taboola.png'

interface Props {
  user?: User []
  campaignSummary: CampaignSummaryModel
  setCampaignToDelete: (id: number, name: string) => void
}

const SummaryCard = ({ user, campaignSummary, setCampaignToDelete }: Props) => {
  const navigate = useNavigate()

  const dataResultGraph = [
    { value: campaignSummary.revenue, color: 'rgb(119 190 77)', label: 'Faturamento' },
    { value: campaignSummary.expenses, color: 'rgb(249 96 96)', label: 'Gastos'}
  ];

  const dataClicksGraph = [
    { value: campaignSummary.clicks, color: 'rgb(119 190 77)', label: 'Clicks' },
  ];

  const dataCheckoutGraph = [
    { value: campaignSummary.checkout, color: 'rgb(119 190 77)', label: 'Start checkout'},
    { value: campaignSummary.clicks - campaignSummary.checkout, color: 'rgb(185 185 185)', label: 'Abandonos' }
  ];

  const dataSalesGraph = [
    { value: campaignSummary.sales, color: 'rgb(119 190 77)', label: 'Conversões'},
    { value: campaignSummary.checkout - campaignSummary.sales, color: 'rgb(185 185 185)', label: 'Abandonos checkout' }
  ];

  const [expenses, setExpenses] = useState<number>(campaignSummary.expenses)
  const [revenue, setRevenue] = useState<number>(campaignSummary.revenue)
  const [roas, setRoas] = useState<number>(campaignSummary.roas)
  const [dataResult, setDataResult] = useState<any>(dataResultGraph)
  const [dataClicks, setDataClicks] = useState<any>(dataClicksGraph)
  const [dataCheckout, setDataCheckout] = useState<any>(dataCheckoutGraph)
  const [dataSales, setDataSales] = useState<any>(dataSalesGraph)
  const [labelDonutResult, setLabelDonutResult] = useState<number>(campaignSummary.revenue-campaignSummary.expenses)
  const [labelDonutClicks, setLabelDonutClicks] = useState<number>(campaignSummary.clicks)
  const [labelDonutCheckout, setLabelDonutCheckout] = useState<number>(campaignSummary.checkout)
  const [labelDonutSales, setLabelDonutSales] = useState<number>(campaignSummary.sales)

  const redirectPage = () => {
    if (campaignSummary.ad_provider === 'taboola') navigate('/campaigns/taboola/' + campaignSummary.id + '/funnels', {state: { campaignSummary: campaignSummary }} )
    else if (campaignSummary.ad_provider === 'meta') navigate('/campaigns/meta/' + campaignSummary.id + '/funnels', {state: { campaignSummary: campaignSummary }} )
  }
  
  const size = {
    width: 100,
    height: 100
  }
/* 
  const updateShownData = (show: string) => {
    if (show.includes('today-tab')) {
      setDataResult(dataResultGraph);
    
      setDataClicks(dataClicksGraph);
    
      setDataCheckout(dataCheckoutGraph);
    
      setDataSales(dataSalesGraph);
      setLabelDonutResult(campaignSummary.revenueToday-campaignSummary.expensesToday)
      setLabelDonutClicks(campaignSummary.clicksToday)
      setLabelDonutCheckout(campaignSummary.checkoutToday)
      setLabelDonutSales(campaignSummary.salesToday)
      setExpenses(campaignSummary.expensesToday)
      setRevenue(campaignSummary.revenueToday)
      setRoas(campaignSummary.roasToday)
    } else {
      setDataResult([
        { value: campaignSummary.revenue, color: 'rgb(119 190 77)', label: 'Faturamento total' },
        { value: campaignSummary.expenses, color: 'rgb(249 96 96)', label: 'Gastos totais'}
      ]);
    
      setDataClicks([
        { value: campaignSummary.clicks, color: 'rgb(119 190 77)', label: 'Clicks' },
      ]);
    
      setDataCheckout([
        { value: campaignSummary.checkout, color: 'rgb(119 190 77)', label: 'Start checkout'},
        { value: campaignSummary.clicks - campaignSummary.checkout, color: 'rgb(185 185 185)', label: 'Abandonos' }
      ]);
    
      setDataSales([
        { value: campaignSummary.sales, color: 'rgb(119 190 77)', label: 'Conversões'},
        { value: campaignSummary.checkout - campaignSummary.sales, color: 'rgb(185 185 185)', label: 'Abandonos checkout' }
      ]);
      setLabelDonutResult(campaignSummary.revenue-campaignSummary.expenses)
      setLabelDonutClicks(campaignSummary.clicks)
      setLabelDonutCheckout(campaignSummary.checkout)
      setLabelDonutSales(campaignSummary.sales)
      setExpenses(campaignSummary.expenses)
      setRevenue(campaignSummary.revenue)
      setRoas(campaignSummary.roas)
    }
    
  } */

  const changeTabs = (tab: string) => {
   /*  const tabClass = $(`#${tab}`).attr('class')?.split(' ')
    if (tab.includes('today-tab') && tabClass && tabClass[tabClass.length-1] !== 'active') {
      $(`#today-tab-${campaignSummary.id}`).addClass('active')
      $(`#all-time-tab-${campaignSummary.id}`).removeClass('active')
      updateShownData(tab)
    } else if (tab.includes('all-time-tab') && tabClass && tabClass[tabClass.length-1] !== 'active') {
      $(`#today-tab-${campaignSummary.id}`).removeClass('active')
      $(`#all-time-tab-${campaignSummary.id}`).addClass('active')
      updateShownData(tab)
    } */
  }

  const openDeleteModal = () => {
    setCampaignToDelete(campaignSummary.id, campaignSummary.name)
  }

  return (
    <CardContainer>
      <Header>
        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}} onClick={() => redirectPage()}>
          <div>
            {campaignSummary.ad_provider === 'taboola' ? (
              <img src={TaboolaLogo} alt="Taboola Logo" style={{ width: '60px', height: '15px' }} />
            ) : campaignSummary.ad_provider === 'meta' ? (
              <img src={MetaLogo} alt="Meta Logo" style={{ width: '60px', height: '15px' }} />
            ) : null}
          </div>

          <Title>{campaignSummary.name}</Title>
          <CampaingId>id campanha: {campaignSummary.id}</CampaingId>
        </div>
        <div style={{paddingBottom: '3px', borderBottom: '1px solid #dfdfdf', width: '30px'}} onClick={() => openDeleteModal()}>
          <DeleteIcon style={{fill: '#6c6b6b'}} />
        </div>
      </Header>

      <TabsContainer>
        <Tab id={`today-tab-${campaignSummary.id}`} className='active' onClick={() => changeTabs(`today-tab-${campaignSummary.id}`)}>Hoje</Tab>
        <Tab id={`all-time-tab-${campaignSummary.id}`} onClick={() => changeTabs(`all-time-tab-${campaignSummary.id}`)} style={{display: 'none'}}>Vitalício</Tab>
      </TabsContainer>

      <SummaryContainer>
        <GraphContainer>
          <PizzaGraph labelChart={'Resultado ($)'} data={dataResult} size={size} labelDonut={Number(labelDonutResult.toFixed(2))}/>
        </GraphContainer>

        <SummaryInfo>
          <SummaryData>Gastos: <strong>${` ${expenses.toFixed(2).replace('.', ',')}`}</strong></SummaryData>
          <SummaryData>Fatur.: <strong>${` ${revenue.toFixed(2).replace('.', ',')}`}</strong></SummaryData>
          <SummaryData>ROAS: <strong>{` ${(roas*100).toFixed(2).replace('.', ',')}`}%</strong></SummaryData>
        </SummaryInfo>
      </SummaryContainer>

      <FunnelStepsContainer>
        <GraphStepContainer>
          <PizzaGraph labelChart={'Clicks'} data={dataClicks} size={size} labelDonut={labelDonutClicks}/>
        </GraphStepContainer>

        <GraphStepContainer>
          <PizzaGraph labelChart={'Checkout'} data={dataCheckout} size={size} labelDonut={Number(labelDonutCheckout.toFixed(2))}/>
        </GraphStepContainer>

        <GraphStepContainer>
          <PizzaGraph labelChart={'Conversões'} data={dataSales} size={size} labelDonut={labelDonutSales}/>
        </GraphStepContainer>
      </FunnelStepsContainer>
    </CardContainer>
  )
}

export default SummaryCard