import styled from 'styled-components'

export const ClientsPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;

`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #dfdfdf;
  gap: 20px;
  overflow: auto;
  box-shadow: 0px 0px 5px 1px black;
  align-items: center;
  padding-top: 50px;

  @media screen and (max-width: 750px) {
    padding: 10px;
    padding-bottom: 50px;
  }
`

export const ClientContainer = styled.div`
  display: flex;
  background: white;
  border-radius: 5px;
  width: 90%;
  max-width: 950px;
  position: relative;

  @media screen and (max-width: 750px) {
      width: 100%;
    }
`

export const IconContainer = styled.div`
  width: 50px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  background: #343434;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 750px) {
    height: 100%;
  }
`

export const InfoContainer = styled.div`
    display: flex;
    padding: 5px 15px 0px 15px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 750px) {
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 5px 5px 0px 5px;
    }
`

export const ClientName = styled.div`
  font-size: 20px;
  font-weight: 200;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  
  @media screen and (max-width: 750px) {
    align-items: start;
  }
`

export const ClientEmail = styled.div`
  font-size: 14px;
  font-weight: 200;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  align-items: center;
  
  @media screen and (max-width: 750px) {
    align-items: start;
  }
`