import { useState } from 'react'
import { ContainerNewCampaign, Content, HeaderNewCampaign } from './styles'
import { User } from '../../../models/user'
import Button from '../../FormElements/Button'
import FormInput from '../../FormElements/FormInput'
import { RequireValidator } from '../../../utils/validators'
import { useForm } from '../../../hooks/form-hook'
import { toast } from 'react-toastify';
import { db } from '../../../db/db'
import Loading from '../../Loading'

interface Props {
    user?: User []
    client?: User
}

const ModalNewAsaasClient = ({ user, client }: Props) => {
    const [loading, setLoading] = useState<Boolean>(false)

    const [formState, inputHandler] = useForm(
        {
            cpfcnpj: {
                value: '',
                isValid: false,
            },
            phone: {
                value: '',
                isValid: false,
            }
        },
        false
    );
   
    const closeModal = () => {
        const modal = document.getElementById('new-client')
        if (modal) modal.style.display = 'none'
    }

    const submitClient = () => {
        if (!formState.isValid) {
            toast.error('Preencha o formulário com dados válidos!')
            return
        }
        setLoading(true)

        const campaignInfo = {
            name: client?.name,
            email: client?.email,
            cpfcnpj: formState.inputs.cpfcnpj.value,
            phone: formState.inputs.phone.value,
        }
        console.log(campaignInfo)
        if (user){
            try {
                //await addCampaign(campaignInfo, user[0].access_token)
                setLoading(false)
                toast.success('Cliente criado com sucesso!')
            } catch (error: any) {
                console.log(error.response)
                setLoading(false)
                if (error.response.status === 403) {
                    db.taboola.clear()
                    db.user.clear()
                }
            }
        }
    }
    
    return (
        <ContainerNewCampaign>
            <HeaderNewCampaign>Criar Cliente Asaas</HeaderNewCampaign>
            
            {loading? (
                <Loading />
            ) : (
                <Content>
                    <FormInput 
                        id='cpfcnpj' 
                        type='number'
                        label='CPF ou CNPJ'
                        invalidMessage='Este campo é obrigatório!'
                        validators={[RequireValidator()]}
                        onInput={inputHandler}
                        placeholder='CPF/CNPJ'
                    />
                    <FormInput 
                        id='phone' 
                        type='text'
                        label='Telefone de contato'
                        invalidMessage='O telefone do cliente é obrigatório!'
                        validators={[RequireValidator()]}
                        onInput={inputHandler}
                        placeholder='Telefone da do cliente'
                    />
                </Content>
            )}
            
            <div style={{paddingBottom: '15px', alignSelf: 'center', display: 'flex', justifyContent: 'center', gap: '20px', width:'100%'}}>
                <Button type='button' size='medium' model='cancel' width='30%' text='Cancelar' mobileWidth='80%' onClick={() => closeModal()} />
                <Button type='submit' size='medium' model='confirm' width='30%' text='Enviar' mobileWidth='80%' onClick={() => submitClient()}/>
            </div>
        </ContainerNewCampaign>
    )
}

export default ModalNewAsaasClient