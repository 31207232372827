import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`
export const GoBackContainer = styled.div`
  display: flex;
  color: rgb(119 190 77);
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  cursor: pointer;
`

export const HeaderTitle = styled.div`
  font-size: 4vh;
  color: #343434;
  font-weight: 200;
`

export const ContainerSummary = styled.div`
  display: flex;
  width: 35%;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    width: 100%;
    padding-bottom: 5px;
  }
`

export const SummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 47%;

  @media screen and (max-width: 750px) {
    padding-top: 5px;
    width: 48%;
  }
`

export const SummaryData = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
`

export const MetaData = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  width: 80%;
  justify-content: space-between;
`
export const MetaTitleContainer = styled.div`
  padding-bottom: 5px;
  justify-content: space-around;
  display: flex;
  width: 100%;
`

export const MetaLogo = styled.img`
  width: 70px;
`

export const ContainerMetaCampaignInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 15%;
  justify-content: center;
  align-items: center;
  background-color: #dfdfdf;
  border-radius: 5px;
  font-size: 18px;
  padding: 5px;
  border: 1px solid transparent;

  &:hover {
    background-color: white;
    cursor: pointer;
    border: 1px solid rgb(119 190 77);
    color: rgb(119 190 77);

    .edit-data {
      fill: rgb(119 190 77) !important;
    }
  }

  @media screen and (max-width: 750px) {
    width: 100%;
    padding: 5px 0px;
  }
`

export const TimePickerContainer = styled.div`
  
  @media screen and (max-width: 750px) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`