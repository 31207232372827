import { useState } from "react"
import { OptimizationData } from "../../models/optimization-data"
import { User } from "../../models/user"
import { MetaAdSetModel } from "../../models/meta-adset-model"
import { AdsetName, AdSetRowContainer, DataRow, IconContainer, OptimizationContainer } from "./styles"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { OptimizationInfo, SelectedPeriod } from "../SiteRow/styles"

interface Props {
    user?: User []
    adSetData: MetaAdSetModel
    pickDate: number
    today: boolean
    yesterday: boolean
    last3: boolean
    last7: boolean
    last15: boolean
    last30: boolean
    last60: boolean
    last90: boolean
    allTime: boolean
}

const AdSetRow = ({ pickDate, adSetData, today, yesterday, last3, last7,
    last15, last30, last60, last90, allTime, user }: Props) => {
    const [showData, setShowData] = useState<boolean>(false)
    const [todayData, setTodayData] = useState<OptimizationData>()
    const [yesterdayData, setYesterdayData] = useState<OptimizationData>()
    const [last3Data, setLast3Data] = useState<OptimizationData>()
    const [last7Data, setLast7Data] = useState<OptimizationData>()
    const [last15Data, setLast15Data] = useState<OptimizationData>()
    const [last30Data, setLast30Data] = useState<OptimizationData>()
    const [last60Data, setLast60Data] = useState<OptimizationData>()
    const [last90Data, setLast90Data] = useState<OptimizationData>()
    const [allTimeData, setAllTimeData] = useState<OptimizationData>()
    
    return (
        <AdSetRowContainer>
            <IconContainer onClick={() => setShowData(!showData)}>
                {showData? (
                    <ExpandLessIcon style={{ fill: 'rgb(119 190 77)', width: '30px', height: '30px' }}/>
                ) : (
                    <ExpandMoreIcon style={{ fill: 'rgb(119 190 77)', width: '30px', height: '30px' }}/>
                )}
            </IconContainer>

            <OptimizationContainer>
                <AdsetName> 
                    {adSetData.name}
                    <strong style={{fontSize: '12px', minWidth: '75px', height: '15px'}}>id: {adSetData.id_ad_set}</strong> 
                </AdsetName>

                <DataRow>
                    <SelectedPeriod>{pickDate === 0? 'Hoje' : pickDate === 1? 'Ontem' : pickDate === 500? 'Vitalício' : pickDate+ ' Dias'}</SelectedPeriod>
                    <OptimizationInfo>Faturamento <strong>{adSetData.summary?.revenue.toFixed(2)}</strong></OptimizationInfo>
                    <OptimizationInfo>Gasto <strong>{Number(adSetData.summary?.expenses).toFixed(2)}</strong></OptimizationInfo>
                    <OptimizationInfo>Clicks <strong>{Number(adSetData.summary?.clicks)}</strong></OptimizationInfo>
                    <OptimizationInfo>Checkout <strong>{adSetData.summary?.checkout}</strong></OptimizationInfo>
                    <OptimizationInfo>Conversões <strong>{adSetData.summary?.sales}</strong></OptimizationInfo>
                    <OptimizationInfo>cpc <strong>{Number(adSetData.summary?.cpc).toFixed(3)}</strong></OptimizationInfo>
                    <OptimizationInfo>cpm <strong>{Number(adSetData.summary?.vcpm).toFixed(2)}</strong></OptimizationInfo>
                    <OptimizationInfo>cpa <strong>{adSetData.summary?.cpa.toFixed(2)}</strong></OptimizationInfo>
                    <OptimizationInfo>ctr <strong>{Number(adSetData.summary?.vctr).toFixed(2)}%</strong></OptimizationInfo>
                    <OptimizationInfo>ROAS <strong>{adSetData.summary? (adSetData.summary.roas*100).toFixed(2): 0.00}%</strong></OptimizationInfo>
                </DataRow>
            </OptimizationContainer>
        </AdSetRowContainer>
    ) 
}

export default AdSetRow