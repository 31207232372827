import React from 'react';
import './App.css';
import AppRoutes from './routes';
import { db } from './db/db'
import { useLiveQuery } from 'dexie-react-hooks'
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App() {
  const userDb = useLiveQuery(() => db.user.toArray())
  const taboolaDb = useLiveQuery(() => db.taboola.toArray())
  const metaInfo = [{
    account_id: 'act_2954150238059609',
    access_token: 'EAAE8DjZCYnGUBO1ATfB1yvro9sScrguMhGdOuxbtevKOE1C7275pdESQeWU6bGqpqCmZA2XdEtPC7bJSDmV3MoVu5crv3DpPbZAoKhFqZAeJZBu1qZCsceRkslmh2HNCQaH5TPeSfqKsNI8tCWS5bLOlEehEO12nOzZC1VJZBqHelYqHQQXyaIjyO00YTBr9z8dcgDjFkyEARRn3uhW4KgZDZD'
  }]
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AppRoutes user={userDb} taboolaInfo={taboolaDb} metaInfo={metaInfo}/>
    </LocalizationProvider>
  );
}

export default App;
