import styled from 'styled-components'

export const ContainerNewCampaign = styled.div`
    max-height: 85vh;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;

    @media screen and (max-width: 750px) {
        width: 90%;
        max-height: 75vh;
    }
`

export const HeaderNewCampaign = styled.div`
    width: 100%;
    font-size: 5vh;
    font-weight: 200;
    height: 10vh;
    background-color: #dfdfdf;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #343434;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Content = styled.div`
    width: 80%;
    padding-top: 15px;
`