import { SettingsPageContainer, ContentContainer, UserSection, SectionTitle } from './styles'
import { User } from '../../models/user'
import HeaderPage from '../../components/HeaderPage'
import { useForm } from '../../hooks/form-hook'
import FormInput from '../../components/FormElements/FormInput'
import { RequireValidator } from '../../utils/validators'
import Button from '../../components/FormElements/Button'
import { addTaboolaInfo } from '../../services'
import { db } from '../../db/db'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { AccountTaboolaDataModel } from '../../models/account-taboola-data'
import { useState } from 'react'
import Loading from '../../components/Loading'

interface Props {
  user?: User []
	taboolaInfo?: AccountTaboolaDataModel[]
  metaAdsInfo?: string[]
}

const IntegrationsPage = ({ user, taboolaInfo, metaAdsInfo }: Props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [showTaboolaForm, setShowTaboolaForm] = useState<boolean>(taboolaInfo? false : true)
  const [showMetaAdsForm, setShowMetaAdsForm] = useState<boolean>(metaAdsInfo? false : true)
  const [formState, inputHandler] = useForm(
    {
      accountId: {
        value: '',
        isValid: false,
      },
      clientId: {
        value: '',
        isValid: false,
      },
      clientSecret: {
        value: '',
        isValid: false,
      },
    },
    false
  );
  const [metaAdsFormState, metaAdsInputHandler] = useForm(
    {
      metaAdsToken: {
        value: '',
        isValid: false,
      },
      adAccountId: {
        value: '',
        isValid: false,
      },
    },
    false
  );

  const submitInfo = async () => {
    if (!formState.isValid) {
      toast.error('Preencha o formulário com dados validos!')
      setLoading(false)
      return
    }
    setLoading(true)
    if (user) {
      try {
        let taboolaInfo = {
          accountId: formState.inputs.accountId.value,
          clientId: formState.inputs.clientId.value,
          clientSecret: formState.inputs.clientSecret.value,
        }

        const tb_token = await addTaboolaInfo(user[0].access_token, taboolaInfo)
        const dexieTaboola = {
          account_id: taboolaInfo.accountId,
          client_id: taboolaInfo.clientId,
          client_secret: taboolaInfo.clientSecret,
          access_token: tb_token.data
        }
        db.taboola.add(dexieTaboola)
        toast.success('Informações salvas com sucesso!')
        setLoading(false)
        setShowTaboolaForm(false)
      } 
      catch (error: any) {
        console.log(error)
        if (error.response.status === 403) {
          db.user.clear()
          db.taboola.clear()
          navigate('/login')
          console.log(error)
          toast.error('Não autorizado!')
        } else {
          setLoading(false)
          toast.error('Tivemos um problema, tente novamente mais tarde!')
        }
      }
    }
  }

  const submitMetaAdsInfo = async () => {
    if (!metaAdsFormState.isValid) {
      toast.error('Preencha o formulário com dados validos!')
      setLoading(false)
      return
    }
    setLoading(true)
    if (user) {
      try {
        const metaInfo = {
          metaAdsToken: metaAdsFormState.inputs.metaAdsToken.value,
          adAccountId: metaAdsFormState.inputs.adAccountId.value,
        }

        console.log(metaInfo)
        toast.success('Informações salvas com sucesso!')
        setLoading(false)
        setShowTaboolaForm(false)
      } 
      catch (error: any) {
        console.log(error)
        if (error.response.status === 403) {
          db.user.clear()
          db.taboola.clear()
          navigate('/login')
          console.log(error)
          toast.error('Não autorizado!')
        } else {
          setLoading(false)
          toast.error('Tivemos um problema, tente novamente mais tarde!')
        }
      }
    }
  }

  return (
    <SettingsPageContainer >
      <HeaderPage title='Integrações'/>
      
      <ContentContainer>
        <UserSection>
          <SectionTitle>Taboola</SectionTitle>
          {!showTaboolaForm? (
            <>
              <small style={{paddingBottom: '15px'}}>Dados já enviados!</small>
              <Button type='submit' size='medium' model='confirm' width='200px' text='Alterar' mobileWidth='80%' onClick={() => {setShowTaboolaForm(true)}}/>
            </>
          ) : (
            loading? (
              <Loading />
            ) : (
              <div style={{maxWidth: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <FormInput 
                  id='clientId' 
                  type='text'
                  label='Client ID'
                  invalidMessage='Campo obrigatório!'
                  validators={[RequireValidator()]}
                  onInput={inputHandler}
                  placeholder='Fornecido pela Taboola'
                />

                <FormInput 
                  id='clientSecret' 
                  type='text'
                  label='Client Secret'
                  invalidMessage='Campo obrigatório!'
                  validators={[RequireValidator()]}
                  onInput={inputHandler}
                  placeholder='Fornecido pela Taboola'
                />

                <FormInput
                  id='accountId' 
                  type='text'
                  label='Account ID'
                  invalidMessage='Campo obrigatório!'
                  validators={[RequireValidator()]}
                  onInput={inputHandler}
                  placeholder='Fornecido pela Taboola'
                />
                
                <div style={{display: 'flex', gap: '15px'}}>
                  <Button type='submit' size='medium' model='cancel' width='50%' text='Cancelar' mobileWidth='80%' onClick={() => setShowTaboolaForm(false)}/>
                  <Button type='submit' size='medium' model='confirm' width='50%' text='Enviar' mobileWidth='80%' onClick={() => submitInfo()}/>
                </div>
              </div>
            ))}
        </UserSection>

        <UserSection>
          <SectionTitle>Meta Ads</SectionTitle>
          {!showMetaAdsForm ? (
            <>
              <small style={{paddingBottom: '15px'}}>Dados já enviados!</small>
              <Button type='submit' size='medium' model='confirm' width='200px' text='Alterar' mobileWidth='80%' onClick={() => {setShowMetaAdsForm(true)}}/>
            </>
          ) : (
            loading ? (
              <Loading />
            ) : (
              <div style={{maxWidth: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <FormInput 
                  id='metaAdsToken' 
                  type='text'
                  label='Meta Ads Token'
                  invalidMessage='Campo obrigatório!'
                  validators={[RequireValidator()]}
                  onInput={metaAdsInputHandler}
                  placeholder='Insira o token do Meta Ads'
                />
                <FormInput 
                  id='adAccountId' 
                  type='text'
                  label='Ad Account ID'
                  invalidMessage='Campo obrigatório!'
                  validators={[RequireValidator()]}
                  onInput={metaAdsInputHandler}
                  placeholder='Insira o ID da conta de anúncios'
                />
                
                <div style={{display: 'flex', gap: '15px'}}>
                  <Button type='submit' size='medium' model='cancel' width='50%' text='Cancelar' mobileWidth='80%' onClick={() => setShowMetaAdsForm(false)}/>
                  <Button type='submit' size='medium' model='confirm' width='50%' text='Enviar' mobileWidth='80%' onClick={() => submitMetaAdsInfo()}/>
                </div>
              </div>
            )
          )}
        </UserSection>
      </ContentContainer>
      <ToastContainer />
    </SettingsPageContainer>
  )
}

export default IntegrationsPage

