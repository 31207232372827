import { GraphContainer, Label, StyledText } from './styles'
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';

interface dataGraph {
  value: number
  color: string
  label: string
}
interface Props {
  labelChart: string
  labelDonut: number
  data: dataGraph[]
  size: any
}


function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

function PieChartWithCenterLabel(data: any, label: string, size:any) {
  return (
    <PieChart 
      series={[{ data, innerRadius: 30, outerRadius: 35 }]}
      margin={{ right: 5 }}
      slotProps={{ legend: { hidden: true } }}

      {...size}
    >
      <PieCenterLabel>{label}</PieCenterLabel>
    </PieChart>
  );
}
//Math.ceil(data[0].value/data[1].value*100)%

const PizzaGraph = ({ labelChart, data, labelDonut, size }: Props) => {
  const labelDonutText = labelDonut.toString().includes('.')? labelDonut.toFixed(2).replace('.', ',') : labelDonut
  return (
    <GraphContainer>
      <Label>{labelChart}</Label>
      {PieChartWithCenterLabel(data, labelDonutText.toString(), size)}
    </GraphContainer>
  )
}

export default PizzaGraph
