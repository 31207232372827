import styled from 'styled-components'

export const AdSetRowContainer = styled.div`
    display: flex;
    background: white;
    border-radius: 5px;
    width: 90%;
    max-width: 950px;
    position: relative;

    @media screen and (max-width: 750px) {
        width: 100%;
    }
`

export const IconContainer = styled.div`
    width: 50px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    background: #343434;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: 750px) {
        height: 100%;
    }
`

export const OptimizationContainer = styled.div`
    display: flex;
    padding: 5px 15px 0px 15px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 750px) {
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 5px 5px 0px 5px;
    }
`

export const AdsetName = styled.div`
    font-size: 20px;
    font-weight: 200;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    
    @media screen and (max-width: 750px) {
        align-items: start;
    }
`

export const DataRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5px;
    padding: 10px 0px;

    @media screen and (max-width: 750px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`