import { CampaingLayoutPageContainer } from './styles'
import { useEffect, useState } from 'react'
import { User } from '../../models/user'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import HeaderPage from './Header/index'
import { loadCampaignMetaOptimizationData } from '../../services'
import { CampaignModel } from '../../models/campaing'
import { db } from '../../db/db'
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { MetaAccountModel } from '../../models/meta-account-model'
import { getMetaCampaignData } from '../../services/meta'
import { CampaignMetaData } from '../../models/campaign-meta-data'

interface Props {
  user?: User []
  metaInfo?: MetaAccountModel[]
}

const CampaignMetaPageLayout = ({ user, metaInfo }: Props) => {
  const navigate = useNavigate()
  const location = useLocation();
  const dataLocation = location.state;

  const [pickDate, setPicDate] = useState<number>(dataLocation.pickDate? dataLocation.pickDate : 0)
  const [loading, setLoading] = useState<boolean>(true)
  const [campaignData, setCampaignData] = useState<CampaignModel>()
  const [metaData, setMetaData] = useState<CampaignMetaData>()

  useEffect(() => {
    const controller = new AbortController();
    if (user && user.length>0) {
      loadCampaignMetaOptimizationData(user[0].access_token, dataLocation.campaignSummary.id, pickDate, controller)
      .then(resp => {
        setCampaignData(resp.data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        if (err.response.status === 403) {
          db.taboola.clear()
          db.user.clear()
          navigate('/login')
        }
      }) 
      return () => controller.abort();
    }
  }, [user, pickDate, navigate, dataLocation])

  useEffect(() => {
    const controller = new AbortController()
    if (metaInfo && metaInfo.length>0) {
      getMetaCampaignData(dataLocation.campaignSummary.external_id, metaInfo[0].access_token, controller)
      .then(resp => {
        setMetaData(resp.data)
      })
      .catch(err => {
        console.log(err)
      })
      return () => controller.abort();
    }
  }, [metaInfo, dataLocation])

  const selectClick = (selectedPeriod: number) => {
    setPicDate(selectedPeriod)
    if (selectedPeriod !== pickDate) setLoading(true)
  }

  return (
    <CampaingLayoutPageContainer>
      <HeaderPage campaign={campaignData} pickDate={pickDate} selectClick={selectClick} metaData={metaData} />

      <Outlet context={{ pickDate, dataLocation, loading, campaignData }}/>

      <ToastContainer />
    </CampaingLayoutPageContainer>
  )
}

export default CampaignMetaPageLayout