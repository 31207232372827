import { CampaingAdsPageContainer, TabsContainer, ContentContainer, Tab, AdsContainer} from './styles'
import { useEffect, useState } from 'react'
import { User } from '../../../models/user'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { loadAllMetaAdsets } from '../../../services'
import Loading from '../../../components/Loading'
import { db } from '../../../db/db'
import { MetaAdSetModel } from '../../../models/meta-adset-model'
import { MetaAccountModel } from '../../../models/meta-account-model'
import AdSetRow from '../../../components/AdSetRow'

interface Props {
  user?: User []
  metaInfo?: MetaAccountModel[]
}

const CampaingMetaAdSetsPage = ({ user, metaInfo }: Props) => {
  const navigate = useNavigate()

  const [loadingAds, setLoadingAds] = useState<boolean>(true)
  const [adSets, setAdSets] = useState<MetaAdSetModel[]>()
  const { pickDate, dataLocation, loading, optimizationData } = useOutletContext<any>();

  const [today, setToday] = useState<boolean>(true)
  const [yesterday, setYesterday] = useState<boolean>(true)
  const [last3, setLast3] = useState<boolean>(true)
  const [last7, setLast7] = useState<boolean>(true)
  const [last15, setLast15] = useState<boolean>(false)
  const [last30, setLast30] = useState<boolean>(false)
  const [last60, setLast60] = useState<boolean>(false)
  const [last90, setLast90] = useState<boolean>(false)
  const [allTime, setAllTime] = useState<boolean>(false)

  useEffect(() => {
    const controller = new AbortController();
    if (user && user.length>0) {
      loadAllMetaAdsets(user[0].access_token, dataLocation.campaignSummary.id, pickDate, controller)
      .then(resp => {
        console.log(resp)
        setAdSets(resp.data)
        setLoadingAds(false)
      })
      .catch(err => {
        console.log(err)
      })
      return () => controller.abort();
    }
  }, [user, pickDate, dataLocation, navigate, optimizationData, metaInfo])

  const redirectPage = (tab: string) => {
    navigate('/campaigns/meta/' + dataLocation.campaignSummary.id + `/${tab}`, {state: { campaignSummary: dataLocation.campaignSummary }})
  }

  return (
    <CampaingAdsPageContainer>
        <ContentContainer>
          <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px', width: '100%'}}>
            <TabsContainer>
                <Tab onClick={() => redirectPage('funnels')}>Funis</Tab>
                <Tab className='active'>Ad Sets</Tab>
            </TabsContainer>

          </div>

          <AdsContainer>
            {loadingAds? (
              <Loading />
            ) : (
              adSets && adSets.length>0? (
                adSets.map((item, index) => (
                  <AdSetRow key={index} pickDate={pickDate} adSetData={item} today={today} yesterday={yesterday} last3={last3} last7={last7} last15={last15} last30={last30} last60={last60} last90={last90} allTime={allTime} />
                ))
              ) : (
                'Nenhum click registrado no periodo selecionado.'
              )
            )}

          </AdsContainer>
        </ContentContainer>
    </CampaingAdsPageContainer>
  )
}


export default CampaingMetaAdSetsPage