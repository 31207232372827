import styled from "styled-components";

export const FunnelLine = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    padding: 10px 10px 15px 10px;
    border-radius: 5px;
    width: 45%;
    max-width: 550px;
    
    @media screen and (max-width: 1050px) {
        width: 75%;
    }

    @media screen and (max-width: 750px) {
        width: 95%;
    }
`

export const FunnelTitle = styled.div`
    font-size: 20px;
    font-weight: 400;
`

export const FunnelLink = styled.div`
    font-size: 12px;
    color: #757575;
    padding-top: 3px;
`

export const DataContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
`

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40%;

    @media screen and (max-width: 750px) {
        width: 50%;
    }
`

export const StepsContainer = styled.div`
    display: flex;
    padding-top: 10px;
    justify-content: space-around;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    @media screen and (max-width: 750px) {
        gap: 5px;
    }
`

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Arrow = styled.div`
    width: 0px;
    height: 0px;
    border-radius: 5px;
    position: relative;
    left: -13px;
`

export const Dot = styled.div`
    width: 14px;
    height: 14px;
    background: rgb(119 190 77);
    position: relative;
    bottom: -8px;
    border-radius: 7px;
`

export const Line = styled.div`
    width: 75%;
    height: 2px;
    background: #dfdfdf;

    @media screen and (max-width: 750px) {
        width: 75%;
    }
`

export const Percentage = styled.div`
    position: relative;
    font-size: 10px;
    height: 0px;
    left: -45px;

    @media screen and (max-width: 750px) {
        left: -40px;
    }
`

export const DataValues = styled.div`
    font-size: 12px;
    display: flex;
    justify-content: space-between;
`