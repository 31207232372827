import { FormEvent } from 'react'
import FormInput from '../../components/FormElements/FormInput'
import Button from '../../components/FormElements/Button'
import { db } from '../../db/db'
import { useForm } from '../../hooks/form-hook'
import { RequireValidator, MinLengthValidator, MaxLengthValidator, EmailValidator } from '../../utils/validators'
import { Container, FormContainer, LogoImg } from './styles'
import { loadAccountTaboolaData, loadUserData, login } from '../../services'
import Logo from '../../assets/logoLogin.png'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const LoginPage = () => {
  const [formState, inputHandler] = useForm(
    {
      email: {
        value: '',
        isValid: false,
      },
      password: {
        value: '',
        isValid: false,
      },
    },
    false
  );

  const submitLogin = async (event: FormEvent) => {
    event?.preventDefault()
    if (!formState.isValid) {
      toast.error('Preencha o formulário com dados validos')
      return
    }

    const userInfo = {
      email: formState.inputs.email.value,
      password: formState.inputs.password.value
    }

    try {
      const controller = new AbortController();
      const loginResponse = await login(userInfo)
      const { accessToken } = loginResponse.data
      const user = await loadUserData(accessToken, controller)
      const taboolaInfo = await loadAccountTaboolaData(accessToken)

      db.user.add(user.data)
      db.taboola.add(taboolaInfo.data)
    } catch (error) {
      toast.error('Email ou senha incorretos!')
      console.log(error)
    }
  }

  return (
    <Container>
      <LogoImg src={Logo} alt="Logo"/>

      <FormContainer onSubmit={submitLogin}>
        <FormInput 
          id='email' 
          type='email'
          label='Email'
          invalidMessage='Please, enter a valid email'
          validators={[EmailValidator()]}
          onInput={inputHandler}
          placeholder='Email'
        />

        <FormInput 
          id='password' 
          type='password'
          label='Password'
          invalidMessage='Password not valid! Should have between 6 and 20 characters'
          validators={[RequireValidator(), MinLengthValidator(5), MaxLengthValidator(20)]}
          onInput={inputHandler}
          placeholder='Password'
        />

        <Button type='submit' size='medium' model='confirm' width='80%' text='Login' mobileWidth='80%'/>
      </FormContainer>

      <ToastContainer />
    </Container>
  )
}

export default LoginPage;