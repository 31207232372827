import { CampaingsPageContainer, ContentContainer } from './styles'
import { useEffect, useState } from 'react'
import { User } from '../../models/user'
import { deleteCampaign, loadUserCampaings, loadUserData } from '../../services'
import SummaryCard from '../../components/CampaingElements/SummaryCard'
import HeaderPage from '../../components/HeaderPage'
import ModalNewCampaign from '../../components/Modals/NewCampaignModal'
import { CampaignSummaryModel } from '../../models/campaing-summary'
import Loading from '../../components/Loading'
import { db } from '../../db/db'
import { useNavigate } from 'react-router-dom'
import ModalDeleteCampaign from '../../components/Modals/DeleteCampaign'
import { CampaignModel } from '../../models/campaing'
import { AccountTaboolaDataModel } from '../../models/account-taboola-data'
import ModalAllowClicks from '../../components/Modals/AllowClicks'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

interface Props {
  user?: User []
  taboolaInfo?: AccountTaboolaDataModel[]
}

interface CampaignDelete {
  id: number
  name: string
}

const campaignsByAccountType = [
  {
    type: 'inicial',
    max: 1
  },
  {
    type: 'basico',
    max: 5
  },
  {
    type: 'cresc',
    max: 10
  },
  {
    type: 'essen',
    max: 20
  },
  {
    type: 'expan',
    max: 30
  },
  {
    type: 'escal',
    max: 40
  },
  {
    type: 'senior',
    max: 1000
  },
  {
    type: 'mestre',
    max: 1000
  },
  {
    type: 'ancient',
    max: 1000
  },
  {
    type: 'admin',
    max: 1000
  }
]

const CampaingsPage = ({ user, taboolaInfo }: Props) => {
  const [campaings, setCampaings] = useState<CampaignSummaryModel[]>()
  const [campaingDelete, setCampaingDelete] = useState<CampaignDelete>()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false)
  const [allowClicks, setAllowClicks] = useState<boolean>(true)

  const navigate = useNavigate()

  useEffect(() => {
    const controller = new AbortController();
    if(user && user.length>0) {
      loadUserCampaings(user[0].access_token, controller)
      .then(resp => {
        setCampaings(resp.data)
        setLoading(false)
      })
      .catch(error => {
        if (error.response.status === 403) {
            db.user.clear()
            db.taboola.clear()
            navigate('/login')
        }
        console.log(error)
      })
      return () => controller.abort();
    }
  },[user, navigate])

  useEffect(() => {
    const controller = new AbortController();
    if (user && user[0]) {
      loadUserData(user[0].access_token, controller)
      .then (resp => {
        setAllowClicks(resp.data.allow_clicks)
      })
      .catch(err => {
        console.log(err)
      })
      return () => controller.abort();
    }
  }, [user])

  const closeModalAllowClicks = () => {
    setAllowClicks(!allowClicks)
  }

  const openNewCampanign = () => {
    const modal = document.getElementById('new-campaign')
    let indexAccType
    if (user && user[0] ) {
      indexAccType = campaignsByAccountType.findIndex((element: any) => element.type === user[0].user_type);
      if (taboolaInfo && taboolaInfo[0]) {
        if (modal && campaings && campaings.length < campaignsByAccountType[indexAccType].max) {
          modal.style.display = 'flex'
        } else {
          alert('Você atingiu o número máximo de campanhas. Exclua alguma ou atualize seu plano!')
        }
      } else (
        alert('É necessário cadastrar suas chaves do Taboola Backstage API antes de criar campanhas.')
      )
    }
  }

  const updateCampaingsAdd = (campaing: CampaignModel) => {
    const addedCampaign = {
      id: campaing.id,
      id_user: campaing.id_user,
      name: campaing.name,
      ad_provider: campaing.ad_provider,
      expenses: 0,
      revenue: 0,
      clicks: 0,
      sales: 0,
      checkout: 0,
      roas: 0,
    }
    if (campaings) {
      const newArr = [...campaings, addedCampaign]
      setCampaings(newArr)
    }
  }

  const createCampaignStatusMessage = (valid: boolean, status?: boolean) => {
    if (!valid) toast.error('Preencha o formulário com dados válidos!')
    else if (status) toast.success('Campanha criada com sucesso!')
    else toast.error('Tivemos um problema, tente novamente mais tarde!')
  }

  const submitDelete = (id: number) => {
    if (id && user) {
      setLoadingDelete(true)
      deleteCampaign(id, user[0].access_token)
      .then(resp => {
        setCampaignToDelete(0, '')
        let deleteIndex = 0
        // eslint-disable-next-line array-callback-return
        campaings?.map((item, index) => {
          if (item.id === id) deleteIndex = index
        })
        campaings?.splice(deleteIndex, 1)
        setCampaings(campaings)
        setLoadingDelete(false)
        toast.success('Campanha deletada com sucesso!')
      })
      .catch(error => {
          console.log(error)
          setLoadingDelete(false)
          toast.error('Tivemos um problema, tente novamente mais tarde!')
          if (error.response.status === 403) {
            db.user.clear()
            db.taboola.clear()
            navigate('/login')
        }
      })
    }
  }

  const setCampaignToDelete = (id: number, name: string) => {
    if (id === 0) {
      setCampaingDelete(undefined)
    } else {
      setCampaingDelete({id: id, name: name})
    }
  }

  useEffect(() => {
    const modal = document.getElementById('delete-campaign')
    if (modal) {
      if (campaingDelete) modal.style.display = 'flex'
      else modal.style.display = 'none'
    }
  }, [campaingDelete])

  return (
    <CampaingsPageContainer >
      <HeaderPage title='Campanhas' button1='criar campanha' button1Func={openNewCampanign}/>
      <ContentContainer>
        {loading? (
          <Loading />
        ) : (
          campaings && campaings.length>0?(
            campaings.map((campaign, index) => (
              <SummaryCard key={index} campaignSummary={campaign} setCampaignToDelete={setCampaignToDelete} />
          ))
          ) : (
            'Nenhuma campanha criada até o momento.'
          )
        )}
      </ContentContainer>
    
      <div id='new-campaign' className='modal'>
          {user && taboolaInfo && <ModalNewCampaign user={user} taboolaInfo={taboolaInfo} updateCampaignArr={updateCampaingsAdd} createCampaignStatusMessage={createCampaignStatusMessage}/>}
      </div>

      <div id='delete-campaign' className='modal'>
          {user && <ModalDeleteCampaign user={user} name={campaingDelete?.name} id={campaingDelete?.id} loading={loadingDelete} setCampaignToDelete={setCampaignToDelete} submitDelete={submitDelete}/>}
      </div>

      <div id='delete-campaign' className='modal' style={{display: allowClicks? 'none' : 'flex'}}>
          {user && <ModalAllowClicks closeModalAllowClicks={closeModalAllowClicks}/>}
      </div>
      
      <ToastContainer />
    </CampaingsPageContainer>
  )
}

export default CampaingsPage
