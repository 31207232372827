import { useNavigate } from 'react-router-dom';
import { CampaignModel } from '../../../models/campaing'
import { HeaderContainer, GoBackContainer, HeaderTitle, ContainerSummary, SummaryData, SummaryColumn, TimePickerContainer, ContainerMetaCampaignInfo, MetaData, MetaTitleContainer, MetaLogo } from './styles'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TimePicker from '../../../components/TimePicker';
import Loading from '../../../components/Loading/Small';
import { CampaignMetaData } from '../../../models/campaign-meta-data';
import metaLogo from '../../../assets/meta.png'
import EditIcon from '@mui/icons-material/Edit';

interface Props {
    campaign?: CampaignModel
    pickDate: number
    metaData?: CampaignMetaData
    selectClick: (days: number) => void
}

const HeaderPage = ({campaign, pickDate, metaData, selectClick}: Props) => {
    const navigate = useNavigate()

    const redirectPage = () => {
        navigate('/campaigns/')
    }

    return(
        <HeaderContainer>
            <div>
                <GoBackContainer onClick={() => redirectPage()}>
                    <ExpandLessIcon style={{ 
                        fill: 'rgb(119 190 77)',
                        width: '25px', 
                        height: '25px', 
                        rotate: '270deg',
                        top: '50px'
                    }} />
                    voltar
                </GoBackContainer>
                <HeaderTitle>{campaign?.name}</HeaderTitle>
            </div>

            <ContainerSummary>
                <SummaryColumn>
                    <SummaryData>Gasto: <strong>$ {campaign?.summary?.expenses.toFixed(2).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>Fatur.: <strong>$ {campaign?.summary?.revenue.toFixed(2).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>Clicks: <strong>{campaign?.summary?.clicks}</strong></SummaryData>
                    <SummaryData>Checkout: <strong>{campaign?.summary?.checkout}</strong></SummaryData>
                    <SummaryData>Conversões: <strong>{campaign?.summary?.sales}</strong></SummaryData>
                </SummaryColumn>

                <SummaryColumn>
                    <SummaryData>ROAS: <strong>{campaign?.summary && (campaign.summary.roas*100).toFixed(2).replace('.', ',')}%</strong></SummaryData>
                    <SummaryData>cpc: <strong>{campaign?.summary?.cpc.toFixed(3).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>cpm: <strong>{campaign?.summary?.vcpm.toFixed(2).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>cpa: <strong>{campaign?.summary?.cpa.toFixed(2).replace('.', ',')}</strong></SummaryData>
                    <SummaryData>ctr: <strong>{campaign?.summary?.vctr.toFixed(2).replace('.', ',')}%</strong></SummaryData>
                </SummaryColumn>
            </ContainerSummary>

            <ContainerMetaCampaignInfo>
                {metaData? (
                    <>
                        <MetaTitleContainer>
                            <MetaLogo src={metaLogo}/>
                            <EditIcon className="edit-data" style={{ fill: '#000', width: '15px', height: '15px' }}/>    
                        </MetaTitleContainer>

                        <MetaData>Budget: <strong>{metaData.daily_budget? '$' + String((metaData.daily_budget/100).toFixed(2)).replace('.', ',') : 'ABO'}</strong></MetaData>
                        <MetaData>Restante: <strong>${metaData.budget_remaining? String((metaData.budget_remaining/100).toFixed(2)).replace('.', ',') : '0,00'}</strong></MetaData>
                        <MetaData>Status: <strong>{metaData.status === 'ACTIVE'? 'Ativa' : 'Pausada'}</strong></MetaData>
                    </>
                ) : (
                    <Loading />
                )}
                
            </ContainerMetaCampaignInfo> 
            
            <TimePickerContainer>
                <TimePicker selected={pickDate} selectClick={selectClick} />            
            </TimePickerContainer>
        </HeaderContainer>
    )
}

export default HeaderPage