import { CampaingFunnelsPageContainer, TabsContainer, ContentContainer, Tab, FunnelsContainer} from './styles'
import { useEffect, useState } from 'react'
import { User } from '../../../models/user'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { CampaignStepsSummary } from '../../../models/campaign-steps-summary'
import { loadFunnelsByTime } from '../../../services'
import FunnelSummaryLine from '../../../components/CampaingElements/FunnelSummary'
import Loading from '../../../components/Loading'
import { db } from '../../../db/db'

interface Props {
  user?: User []
}

const CampaingFunnelsPage = ({ user }: Props) => {
  const navigate = useNavigate()

  const [stepsSummary, setStepsSummary] = useState<CampaignStepsSummary>()
  const [loadingFunnels, setLoadingFunnels] = useState<boolean>(true)

  const { pickDate, dataLocation, loading, optimizationData } = useOutletContext<any>();

  useEffect(() => {
    const controller = new AbortController();
    if (user && user.length>0) {
      loadFunnelsByTime(user[0].access_token, dataLocation.campaignSummary.id, pickDate, controller)
      .then(resp => {
        setStepsSummary(resp.data)
        setLoadingFunnels(false)
      })
      .catch(err => {
        if (err.response.status === 403) {
          db.taboola.clear()
          db.user.clear()
          navigate('/login')
        }
        console.log(err)
      })
      return () => controller.abort();
    }
    
  }, [pickDate, user, dataLocation, navigate])

  const redirectPage = (tab: string) => {
    navigate('/campaigns/taboola/' + dataLocation.campaignSummary.id + `/${tab}`, {state: { campaignSummary: dataLocation.campaignSummary }})
  }

  return (
    <CampaingFunnelsPageContainer>
      <ContentContainer>
        <TabsContainer>
            <Tab className='active'>Funis</Tab>
            <Tab onClick={() => redirectPage('ads')}>Ads</Tab>
            <Tab onClick={() => redirectPage('sites')}>Sites</Tab>
        </TabsContainer>

        <FunnelsContainer>
          {loadingFunnels || loading? (
            <Loading />
          ) : (
            stepsSummary && optimizationData?.summary &&(
              <FunnelSummaryLine clicks_taboola={optimizationData.summary.clicks} stepsSummary={stepsSummary} expenses={optimizationData.summary.expenses}/>
            )
          )}
        </FunnelsContainer>
      </ContentContainer>
    </CampaingFunnelsPageContainer>
  )
}

export default CampaingFunnelsPage