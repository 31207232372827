import styled from "styled-components"

export const SiteRowContainer = styled.div`
    display: flex;
    background: white;
    border-radius: 5px;
    width: 90%;
    max-width: 950px;
    position: relative;

    @media screen and (max-width: 750px) {
        width: 100%;
    }
`

export const IconContainer = styled.div`
    width: 50px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    background: #343434;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media screen and (max-width: 750px) {
        height: 100%;
    }
`

export const OptimizationContainer = styled.div`
    display: flex;
    padding: 5px 15px 0px 15px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 750px) {
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 5px 5px 0px 5px;
    }
`

export const PlusMinusContainer = styled.div`
    display: none;
    gap: 3px;
    padding-top: 10px;
`

export const PlusBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    background-color: #222222;
    color: rgb(119, 190, 77);
    width: 35px;
    height: 30px;
    font-size: 25px;
    cursor: pointer;
`

export const MinusBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    background-color: #222222;
    color: rgb(119, 190, 77);
    width: 35px;
    height: 30px;
    font-size: 30px;
    cursor: pointer;
`

export const SiteDomain = styled.div`
    font-size: 20px;
    font-weight: 200;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    
    @media screen and (max-width: 750px) {
        align-items: start;
    }
`

export const DataRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5px;
    padding: 10px 0px;

    @media screen and (max-width: 750px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`

export const SelectedPeriod = styled.div`
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #757575;
    padding: 5px 0px;
    color: white;
    border-radius: 5px;
    width: 60px;

    @media screen and (max-width: 750px) {
        width: 100%;
    }
`

export const OptimizationInfo = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: flex-start;
    align-items: center;
    min-width: 60px;
    padding: 2px 0px;

    @media screen and (max-width: 750px) {
        padding: 4px 0px;
        min-width: 65px;
    }
`

export const CompareDataContainer = styled.div`
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    gap: 10px;

    @media screen and (max-width: 750px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
        padding-bottom: 10px;
    }
`

export const Period = styled.div`
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    border: 1px solid rgb(119 190 77);
    color: rgb(119 190 77);
    width: 50px;

    @media screen and (max-width: 750px) {
        width: 100%;
    }
`

export const ExtraOptimizationInfo = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 10px;
    justify-content: flex-start;
    align-items: center;
    min-width: 60px;
    padding: 2px 0px;

    @media screen and (max-width: 750px) {
        padding: 4px 0px;
    }
`

export const CpcInputContainer = styled.div`
    display: none;
    position: absolute;
    border-radius: 5px;
    background: white;
    border: 1px solid #dfdfdf;
    padding: 5px;
    flex-direction: column;
    right: 30px;
    top: 3px;
    font-size: 14px;
`

export const InputContainer = styled.div`
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 5px; 
    font-size: 14px;

`

export const CpcInput = styled.input`
    width: 35px;
    text-align: center;
    border: none;
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }

    &:focus-visible {
        outline: none;
    }
`

export const SendBtn = styled.button`
    background: white;
    color: rgb(119, 190, 77);
    border: 1px solid rgb(119, 190, 77);
    padding: 5px;
    width: 60px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.4s cubic-bezier(0, 0, 0.11, 1.17);

    &:hover {
        background-color: rgb(119, 190, 77);
        color: white;
    }
`

export const CancelBtn = styled.button`
    background: white;
    color: rgb(249, 96, 96);
    border: 1px solid rgb(249, 96, 96);
    padding: 5px;
    width: 60px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.4s cubic-bezier(0, 0, 0.11, 1.17);

    &:hover {
        background-color: rgb(249, 96, 96);
        color: white;
    }
`